<template>
  <ion-page>
    <app-header back="home" title="실시간 출하현황" />
    <template v-if="isConnected">
      <ion-card>
        <section>
          <ion-grid>
            <ion-row>
              <ion-col size-md="0.5" size-xs="1" @click="dateBack">
                <ion-icon :icon="arrowBack"></ion-icon>
              </ion-col>
              <ion-col size-md="2" size-xs="4">
                <ion-input class="calendar" v-model="frDate" id="frdate" readonly @click="popChange">
                </ion-input>
                <ion-popover trigger="frdate" @didDismiss="onWillDismissPopover">
                  <ion-datetime display-format="YYYY-MM-DD" min="2010-01-01" v-model="frDate" presentation="date"
                    :show-default-buttons="true"></ion-datetime>
                </ion-popover>
              </ion-col>
              <ion-col size-md="0.5" size-xs="1" @click="dateForward">
                <ion-icon :icon="arrowForward"></ion-icon>
              </ion-col>
              <ion-col size-md="4" size-xs="6">
                <ion-input id="ddd" class="ion-text-center" readonly placeholder="영업소검색"
                  style="text-align: right; align-items: center; margin: auto;" @click="searchOpenModal" @ionInput="cgname($event)"></ion-input>
              </ion-col>
              <ion-col size-md="2" size-xs="6">
                <ion-button expand="block" class="excel" @click="exportToExcel" color="success">엑셀변환</ion-button>
              </ion-col>
              <ion-col size-md="2" size-xs="6">
                <ion-button expand="block" color="medium" @click="listSearch">조회</ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </section>
      </ion-card>
      <ion-item>
        <ion-label style="flex: 0.15; margin: 0;">
          <h3 class="set-cell">순번</h3>
          <h3 class="set-cell">영업소</h3>
          <h3 class="set-cell">출하시간</h3>
        </ion-label>
        <ion-label style="flex: 0.5; margin: 0;">
          <h3 class="set-cell">착지</h3>
          <h3 class="set-cell">운송사</h3>
          <h3 class="set-cell">차량번호</h3>
        </ion-label>
        <ion-label style="flex: 0.2; margin: 0;">
          <h3 class="set-cell">품목</h3>
          <h3 class="set-cell">사업장</h3>
          <h3 class="set-cell">인도조건</h3>
        </ion-label>
        <ion-label style="flex: 0.15; margin: 0;">
          <h3 class="set-cell" style="text-align: end;">출하</h3>
          <h3 class="set-cell" style="color: red; text-align: end;">출하(t)</h3>
          <h3 class="set-cell" style="text-align: end;">기사</h3>
        </ion-label>
      </ion-item>
      <ion-content>
        <div id="container">
          <ion-list v-for="item in rtsss" :key="item.sq" style="padding: 0; margin: 0;" @click="fnView(item)">
            <ion-item style="padding: 0; margin: 0;">
              <ion-label :style="{ 'background-color': item.dplace_name === '품목소계' ? '#dfdfdf' : 'white' }"
                style="flex: 0.15; margin: 0;">
                <h3 class="set-cell">{{ item.sqno }}</h3>
                <h3 class="set-cell">{{ item.office_name }}</h3>
                <h3 class="set-cell">{{ item.sa_time }}</h3>
              </ion-label>
              <ion-label :style="{ 'background-color': item.dplace_name === '품목소계' ? '#dfdfdf' : 'white' }"
                style="flex: 0.5; margin: 0;">
                <h3 class="set-cell">{{ item.dplace_name }}</h3>
                <h3 class="set-cell">{{ item.sa_clntnm }}</h3>
                <h3 class="set-cell">{{ item.sa_carno }}</h3>
              </ion-label>
              <ion-label :style="{ 'background-color': item.dplace_name === '품목소계' ? '#dfdfdf' : 'white' }"
                style="flex: 0.2; margin: 0;">
                <h3 class="set-cell">{{ item.itnm }}</h3>
                <h3 class="set-cell">{{ item.sicdname }}</h3>
                <h3 class="set-cell">{{ item.delivery_name }}</h3>
              </ion-label>
              <ion-label :style="{ 'background-color': item.dplace_name === '품목소계' ? '#dfdfdf' : 'white' }"
                style="flex: 0.15; margin: 0;">
                <h3 class="set-cell" style="text-align: end;">{{ item.sale_qunt.toLocaleString() }}</h3>
                <h3 class="set-cell" style="color: red; text-align: end;">{{ item.sa_t1.toLocaleString() }}</h3>
                <h3 class="set-cell" style="text-align: end;">{{ item.sa_kisa }}</h3>
              </ion-label>
            </ion-item>
          </ion-list>
        </div>
      </ion-content>
    </template>
    <template v-else>
      <network-error v-on:@onReConnect="onReConnect" />
    </template>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonLabel,
  IonItem,
  IonIcon,
  IonDatetime,
  onIonViewWillEnter,
  IonButton,
  IonCard,
  IonList,
  IonInput,
  IonPopover,
  IonGrid,
  IonCol,
  IonRow,
  popoverController,
  onIonViewWillLeave,
  modalController
} from '@ionic/vue';

import { defineComponent, reactive, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { UseRtss } from '@/api/services/rtss';
import { setDateFormat, getDayOfWeek } from '@/utils/dateFormatter';
import * as XLSX from 'xlsx';
import { arrowBack, arrowForward } from 'ionicons/icons';
import RealTimeDetailModalVue from './modal/RealTimeDetailModal.vue';
import OfficeListModalVue from './modal/OfficeListModal.vue';
import { users } from '@/api/services/user';

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonLabel,
    IonItem,
    IonDatetime,
    IonButton,
    IonCard,
    IonIcon,
    IonList,
    IonInput,
    IonPopover,
    IonGrid,
    IonCol,
    IonRow,
  },
  setup() {
    const frDate = ref(setDateFormat(new Date(), 'YYYY-MM-DD')); //ref('2023-01-01');
    const { isConnected, fetchDatas, year, month, rtsss } = UseRtss();
    const store = useStore();
    const auth = computed(() => store.getters['auth/getData']);
    const { saveLog } = users();
    const salenm = reactive({ title: null, });
    const form = reactive({
      modalState: false,
      popoverState: false
    });

    onIonViewWillEnter(async () => {

      saveLog(auth.value.id, '실시간출하현황');
    });

    async function onWillDismissPopover() {
      form.popoverState = false;
      form.modalState = false;
    }

    async function popChange() {
      form.popoverState = true;
    }

    const openModal = async (item) => {
      // if (item.sa_carno != "ㅤ" && item.delivery_name != "ㅤ" && item.sa_kisa != "ㅤ") {
        const modal = await modalController.create({
          component: RealTimeDetailModalVue,
          cssClass: 'my-modal-class2',
          componentProps:{
            titleName: '출하현황',
            remk1: item.remk1,
            remk2: item.remk2,
            remk3: item.remk3,
            remk4: item.remk4,
            remk5: item.remk5
          }
          //Modal is name of the component to render inside ionic modal
        });
        return modal.present();
      // }
    };


    const exportToExcel = () => {
      const data = Object.values(rtsss.value).map(row => [row.sqno, row.office_name, row.dplace_name, row.itnm, row.sicdname, row.delivery_name,
      row.sa_clntnm, row.sa_kisa, row.sa_carno, row.sa_time, row.sale_qunt, row.sa_t1,]);
      data.unshift(['순번', '영업소', '착지', '품목', '사업장', '인도조건', '운송사', '기사', '차량번호', '출하시간', '출하', '출하(t)'])
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "실시간출하현황.xlsx");
    }

    function cgname(e) {
      if (e == null) {
        document.querySelector('.ion-text-center').value = null;
      } else {
        document.querySelector('.ion-text-center').value = "[" + e + " ]";
      }
    }

    function dateForward() {
      const date = frDate.value.replaceAll('-', '');
      const year = date.substr(0, 4);
      const month = date.substr(4, 2);
      const day = date.substr(6, 2);
      const dateTo = new Date(Number(year), Number(month) - 1, Number(day));
      frDate.value = setDateFormat(dateTo.setDate(dateTo.getDate() + 1), 'YYYY-MM-DD');
    }

    function dateBack() {
      const date = frDate.value.replaceAll('-', '');
      const year = date.substr(0, 4);
      const month = date.substr(4, 2);
      const day = date.substr(6, 2);
      const dateTo = new Date(Number(year), Number(month) - 1, Number(day));
      frDate.value = setDateFormat(dateTo.setDate(dateTo.getDate() - 1), 'YYYY-MM-DD');
    }

    async function searchOpenModal() {

      form.modalState = true;
      const modal =
        await modalController.create({
          component: OfficeListModalVue,
        });
      modal.present();

      const { data, role } = await modal.onWillDismiss();

      if (role === 'confirm') {
        cgname(data.substring(6));
        salenm.title = data.substring(0, 4);
      } else {
        salenm.title = data;
        cgname(salenm.title);
      }
      form.modalState = false;
    }

    onIonViewWillEnter(async () => { // 들어오자 마자 검색?
      // const frdutiesPromise = fetchDatas(frDate.value.substring(0, 10).replaceAll('-', ''), salenm.title);
      // await frdutiesPromise;
    });

    async function onReConnect() {
      const frdutiesPromise = fetchDatas(frDate.value.substring(0, 10).replaceAll('-', ''), salenm.title == null ? '%25' : salenm.title);
      await frdutiesPromise;
    }

    async function listSearch() {   // searchGnmu
      await fetchDatas(frDate.value.substring(0, 10).replaceAll('-', ''), salenm.title == null ? '%25' : salenm.title);
    }

    function fnView(item) {
      openModal(item);
    }

    onIonViewWillLeave(async () => {
      if (form.popoverState == true) {
        await popoverController.dismiss()
      }
    });

    return {
      isConnected,
      year,
      month,
      frDate,
      arrowBack,
      arrowForward,
      rtsss,
      getDayOfWeek,
      onReConnect,
      listSearch,
      exportToExcel,
      onWillDismissPopover,
      popChange,
      // showPopover,
      fnView,
      openModal,
      searchOpenModal,
      dateForward,
      dateBack,
    };
  }
});

</script>
<style scoped>
ion-content {
  text-align: center;
}

ion-col {
  padding: 0;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
}

ion-grid {
  padding: 0;
}

ion-col .calendar {
  padding: 0;
}

ion-popover {
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
}

.set-cell {
  font-size: 13px;
  text-align: left;
}

.title {
  display: block;
  text-align: center;
  font-size: 1.2em;
  padding: 0.5em;
}

section {
  border-bottom: 1px solid #cccccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  width: 100%;
}

ion-card {
  margin: 0px;
  border-radius: 0px;
}</style>