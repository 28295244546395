import { Ref, ref } from 'vue';
import { $loading } from '@/api/index';
import { setDateFormat } from '@/utils/dateFormatter';
import { toast } from '@/utils/toast';

export interface Rtss {
  sqno: string;
  sicdname: string;
  sicdcode: string;
  sa_date: string;
  sale_qunt: string;
  sa_t1: string;
  office_code: string;
  office_name: string;
  salecode: string;
  salename: string;
  dplace_code: string;
  dplace_name: string;
  tpctname: string;
  itnmcode: string;
  itnm1: string;
  itnm: string;
  salesano: string;
  sa_sano: string;
  sa_clntcode: string;
  sa_clntnm: string;
  delivery_code: string;
  delivery_name: string;
  sa_carno: string;
  sa_time: string;
  sa_kisa: string;
  sa_date_nm: string;
  remk1: string;
  remk2: string;
  remk3: string;
  remk4: string;
  remk5: string;
  sq: string;
}



export function UseRtss() {

  const rtsss: Ref<Rtss[]> = ref([]);
  const year = ref(setDateFormat(new Date(), 'YYYY'));
  const month = ref(setDateFormat(new Date(), 'MM'));

  const isConnected = ref(true);

  async function fetchDatas(pcFrdt: string, pcSale: string) {
    await $loading(`/rtss?pc_date=${pcFrdt}&pc_sale=${pcSale}`, 'POST').then(
      (res: any) => {
        if (res.data == ''){
          rtsss.value = [];
          toast('데이터가 존재하지 않습니다.'); 
        } else {
          rtsss.value = null;
          rtsss.value = res.data;
        }
      }
    );
  }      
  
  return {
    isConnected,
    fetchDatas,
    year,
    month,
    rtsss,
  };
}
